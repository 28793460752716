<script lang="ts">
import { cva, cx, type VariantProps } from 'cva';

export const appLayoutSidebarVariants = {
  size: {
    default: undefined,
    small: 'app-layout-sidebar--small',
  },
  collapsible: {
    true: 'app-layout-sidebar--collapsible',
  },
};

const classes = cva({ variants: appLayoutSidebarVariants });

export type AppLayoutSidebarProps = VariantProps<typeof classes>;
</script>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  id?: string;
  size?: AppLayoutSidebarProps['size'];
  sticky?: boolean;
  fixed?: boolean;
}>();

const computedClasses = computed(() => ({
  'app-layout-sidebar--sticky': props.sticky,
  'app-layout-sidebar--fixed': props.fixed,
}));
</script>

<template>
  <div
    ref="root"
    class="app-layout-sidebar"
    :class="cx(classes({ size }), computedClasses)"
  >
    <div ref="sidebar" class="app-layout-sidebar__sidebar">
      <slot name="sidebar" />
    </div>
    <div ref="main" class="app-layout-sidebar__main">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

$sidebar-width-small: 200px;
$sidebar-width: 320px;
$collapsed-sidebar-width: 62px;
$gutter-width: $space-large;

.app-layout-sidebar {
  $self: &;

  flex: 1;
  align-items: flex-start;
  display: flex;
  gap: $space-large;
  flex-direction: row;
  justify-content: flex-start;

  &__sidebar {
    flex-shrink: 0;
    position: relative;
    width: $sidebar-width;
    z-index: layer('overlap');
  }

  &__main {
    display: flex;
    height: 100%;
    align-items: stretch;
    flex-direction: column;
    width: calc(100% - $sidebar-width-small);
  }

  &__gutter {
    position: absolute;
    width: $space-large;
    cursor: pointer;
    height: calc(100vh - $app-header-height - $space-large);
    user-select: none;
    right: -$space-large;
    top: 0;

    &:hover {
      #{$self}__handle {
        &--neutral {
          display: none;
        }
        &--left {
          display: block;
        }
        &--right {
          display: none;
        }
      }
    }
  }

  &__handle {
    color: theme('color-border-primary');
    font-size: $scale-small;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    &--left,
    &--right {
      color: theme('color-fg-success');
      display: none;
    }
  }

  &--small {
    > #{$self}__sidebar {
      width: $sidebar-width-small;
    }
  }

  &--sticky {
    > #{$self} {
      &__sidebar {
        position: sticky;
        top: calc($app-header-height + $space-large);
        z-index: layer('menu');
      }
    }
  }

  &--fixed {
    > #{$self} {
      &__sidebar {
        position: fixed;
        top: 0;
        z-index: layer('menu');
      }
      &__main {
        margin-left: $sidebar-width-small;
      }
    }
  }
}
</style>
